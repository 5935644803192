import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FontWeightEnum, getAmount, TextVariantColorEnum } from '@app/common';
import { Locked, NonVisible, Wrapper } from './value-amount.styled';
import { useRootStore } from '../../../../providers/root-store-provider';
import { getColor } from '../../tables/utils/get-color';
import { getFakeAmount } from '../../../../utils/get-fake-amount';
import { formatBigNumber } from '../utils/format-big-number';

type ValueAmountCommonProps = {
    mode?: 'styled' | 'pure';
    nullValue?: number;
    amount: number;
    locked?: boolean;
    hidable?: boolean;
    currency: string;
    fractionDigits?: number;
    inParentheses?: boolean;
    bigNumber?: boolean;
};

type ValueAmountStyledProps = {
    mode: 'styled';
    fontWeight?: FontWeightEnum;
    color?: TextVariantColorEnum;
};

type ValueAmountPureProps = {
    mode?: 'pure';
};

type ValueAmountProps = ValueAmountCommonProps & (ValueAmountStyledProps | ValueAmountPureProps);

const getMinValue = (fractionDigits: number) => {
    return 10 ** -fractionDigits;
};

export const ValueAmount: React.FC<ValueAmountProps> = observer((props) => {
    const lockedRef = useRef<HTMLDivElement>(null);
    const rootStore = useRootStore();
    const [blur, setBlur] = useState<number>(0.3);

    const {
        amount,
        currency,
        fractionDigits = 2,
        inParentheses = false,
        hidable = false,
        locked = false,
        bigNumber = false,
        nullValue,
    } = props;
    const mode = props.mode ?? 'styled';

    const { portfolioStore, privateStore } = useRootStore();

    const suffix = currency || portfolioStore.selectedPortfolio.portfolioHistoryCurrencyCode;
    const fakeAmount = getFakeAmount(amount);

    let valueBefore: string;
    if (bigNumber) {
        valueBefore = formatBigNumber(locked ? fakeAmount : amount, fractionDigits, suffix || '');
    } else if (amount > 0 && amount < getMinValue(fractionDigits)) {
        valueBefore = `<${getAmount(
            locked ? getFakeAmount(getMinValue(fractionDigits)) : getMinValue(fractionDigits),
            fractionDigits,
            suffix || '',
            nullValue,
        )}`;
    } else {
        valueBefore = getAmount(locked ? fakeAmount : amount, fractionDigits, suffix || '', nullValue);
    }

    const value = inParentheses ? `(${valueBefore})` : valueBefore;

    useEffect(() => {
        if (lockedRef.current) {
            const fontSizeValue = parseFloat(window.getComputedStyle(lockedRef.current).fontSize);
            const blurSize = fontSizeValue / 50;
            const roundedBlurSize = parseFloat(blurSize.toFixed(2));
            setBlur(roundedBlurSize);
        }
    }, []);

    const isHidden = (hidable && privateStore.isPrivateModeEnabled) || !rootStore.cookieService.hasJwtToken();

    if (isHidden) {
        return <NonVisible>****** {suffix}</NonVisible>;
    }

    if (mode === 'styled') {
        const { color, fontWeight = FontWeightEnum.Medium } = props as ValueAmountStyledProps;

        return (
            <Wrapper variant={color || getColor(amount)} fontWeight={fontWeight}>
                <Locked ref={lockedRef} locked={locked} blur={blur}>
                    {value}
                </Locked>
            </Wrapper>
        );
    }

    return locked ? (
        <Locked ref={lockedRef} blur={blur} locked>
            {value}
        </Locked>
    ) : (
        <>{value}</>
    );
});
